import { makeStyles, Box, Typography } from "@material-ui/core";
import React from "react";
import HolderCard from "./HolderCard";
import PieChart from "./PieChart";

import TweetCard from "./TweetCard";
import { useSelector } from "react-redux";
import { TWITTER_HANDLE } from "../../utils/constants";

const TwitterFeedCard = () => {
  const classes = useStyles();

  const twitterData = useSelector((state) => state.web3.data.twitterData);

  console.log("TwitterFeedCard => ", twitterData);

  return (
    <Box className={classes.container}>
      <Box display="flex" marginBottom={"20px"}>
        <Typography className={classes.heading}>Twitter Feed</Typography>
        <Typography className={classes.tag}>{TWITTER_HANDLE}</Typography>
      </Box>

      <Box className={classes.tweetBox}>
        {twitterData?.data.map((tweet) => (
          <TweetCard
            text={tweet.text}
            attachment={tweet.attachments ? tweet?.attachments[0] : null}
            user={twitterData?.includes?.users[0]}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TwitterFeedCard;
const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    minHeight: 270,
    // minWidth: 730,
    // maxHeight: 300,
    background: theme.palette.cardBgColor,
    border: theme.palette.border,
    borderRadius: "25px",
    padding: 24,
    paddingBottom: 0,
    display: "flex",
    flexDirection: "column",
    marginBottom: 24,

    [theme.breakpoints.down("650")]: {
      height: 500,
    },
  },

  heading: {
    fontSize: "24px",
    lineHeight: "19px",
    color: "#fff",
    fontWeight: 500,
    marginRight: 8,
  },

  tag: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "rgba(255, 255, 255, 0.27)",
    fontWeight: 500,
  },

  comingSoonText: {
    fontSize: "22px",
    lineHeight: "28px",
    color: "#fff",
    fontWeight: 500,
    textAlign: "center",
  },

  tweetBox: {
    maxHeight: 330,
    overflow: "scroll",

    [theme.breakpoints.down("1100")]: {
      maxHeight: 400,
    },

    [theme.breakpoints.down("650")]: {
      maxHeight: "100%",
    },
  },
}));
