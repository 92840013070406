import { Button, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import { APP_BTN_BORDER } from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  container: {
    minWidth: 40,
    height: 30,
    marginRight: 5,
  },

  selectedContainer: {
    background: ({ selected }) =>
      selected ? theme.palette.themeColor1 : "#fff",
    border: ({ selected }) =>
      selected ? "2px solid transparent" : APP_BTN_BORDER,
    borderRadius: "10px",
    minWidth: 40,
    height: 30,
    marginRight: 5,

    // [theme.breakpoints.down("sm")]: {
    //   minWidth: 20,
    // },
    "&:hover": {
      color: ({ selected }) => (selected ? "#fff" : "#121B2D"),
      background: ({ selected }) =>
        selected ? theme.palette.themeColor1 : "#fff",
    },
  },
  btnText: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "16px",
    color: ({ selected }) => (selected ? "#000" : "#FAFAFA"),

    opacity: ({ selected }) => (selected ? 1 : 0.6),
  },
}));

const TimeRangeButton = ({ text, selected, onClick }) => {
  const classes = useStyles({ selected });

  return (
    <Button
      onClick={onClick}
      className={selected ? classes.selectedContainer : classes.container}
    >
      <Typography className={classes.btnText}>{text}</Typography>
    </Button>
  );
};

export default TimeRangeButton;
