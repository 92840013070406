import { makeStyles } from "@material-ui/core";
import React from "react";
import { Button } from "@material-ui/core";
import {
  APP_BTN_BACKGROUND,
  APP_BTN_BORDER,
  APP_BTN_BORDER_RADIUS,
  APP_BTN_BOX_SHADOW,
} from "../../utils/constants";

const useStyles = makeStyles((theme) => ({
  timeFrameBtn: {
    background: (active) =>
      active ? theme.palette.themeColor1 : theme.palette.cardBgColor,
    border: (active) => (active ? "2px solid transparent" : APP_BTN_BORDER),
    boxShadow: APP_BTN_BOX_SHADOW,
    borderRadius: APP_BTN_BORDER_RADIUS,
    color: (active) => (active ? "#090613" : "#fff"),
    marginRight: 12,
    fontSize: "12px",
    lineHeight: "10px",
    fontWeight: "600",
    height: 32,
    padding: "0 18px",

    "&:hover": {
      color: (active) => (active ? "#000" : "#fff"),
      background: (active) =>
        active
          ? `rgba(${theme.palette.themeColor1RGBA},0.7)`
          : "rgba(15, 8, 36, 0.55)",
    },
    [theme.breakpoints.between(960, 1000)]: {
      marginRight: 6,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 7px",
      marginBottom: 5,
      fontSize: "11px",
    },
  },
}));

const FilterButton = ({ text, active, onClick }) => {
  const classes = useStyles(active);
  return (
    <Button className={classes.timeFrameBtn} onClick={onClick}>
      {text}
    </Button>
  );
};

export default FilterButton;
