import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalGasStation } from "@material-ui/icons";
import { Box } from "@material-ui/core";
import { useWeb3React } from "@web3-react/core";
import { Card, CardContent, makeStyles, Typography } from "@material-ui/core";

import {
  checkApproval,
  fromWei,
  getBalance,
  giveApproval,
  stakeAmount,
} from "../contract/functions/functions";

import { getChainData, setLoading, showSnackbar } from "../redux";

import { InfoPng } from "../assets/index.js";

import BlockBtn from "../components/stake_components/BlockBtn.js";
import CustomModal from "../components/stake_components/CustomModal.js";
import AmountInput from "../components/stake_components/AmountInput.js";

import {
  APP_STAKING_MESSAGE,
  APP_STAKING_MESSAGE_ETH,
  APP_STAKING_MESSAGE_POLYGON,
  APP_TOKEN_NAME,
} from "../utils/constants.js";

const Stake = () => {
  const classes = useStyles();
  const context = useWeb3React();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState("");
  const [modalLoadText, setModalLoadText] = useState("");

  const [approvalValue, setApprovalValue] = useState(0);

  const [userBalance, setUserBalance] = useState(0);
  const [userInput, setUserInput] = useState();

  const [inputErrText, setInputErrText] = useState("");
  const [showInputErr, setShowInputErr] = useState(false);

  const { loading } = useSelector((state) => state.ui);

  const networkSet = useSelector((state) => state.web3.networkSet);
  const minStakingAmount = useSelector(
    (state) => state.web3.data.minStakingAmount
  );
  const minStakingPeriod = useSelector(
    (state) => state.web3.data.minStakingPeriod
  );

  const stakingPeriodValue = useSelector(
    (state) => state.web3.data.stakingPeriodValue
  );

  const gasPrice = useSelector((state) => state.web3.gasPrice);

  const stakingPeriod = useSelector((state) => state.web3.data.stakingPeriod);

  const userData = useSelector((state) => state.web3.data);

  const currentNetwork = useSelector((state) => state.web3.currentNetwork);
  const tokenBalance = useSelector((state) => state.web3.tokenBalance);

  useEffect(() => {
    async function getApprovalAsync() {
      if (context.account) {
        let approval = await checkApproval(context.account);

        setApprovalValue(fromWei(approval));
      }
    }

    if (networkSet) getApprovalAsync();
  }, [context.account, networkSet, context.chainId, open]);

  useEffect(() => {
    if (context?.account) {
      setUserInput("");
    }
  }, [context?.account, context?.chainId]);

  const changeLoadingStatus = (flag) => {
    dispatch(setLoading(flag));
  };

  const onStake = async (value, balance) => {
    value = Number(value);
    balance = Number(balance);
    console.log(value, balance);
    if (value >= minStakingAmount && value <= balance) {
      await stakeAmount(
        value,
        context.account,
        changeLoadingStatus,
        setOpen,
        showSnackbarFunc,
        setUserInput,
        () =>
          dispatch(
            getChainData(context.account, currentNetwork, context.chainId)
          )
      );
    } else if (value > balance) {
      showSnackbarFunc("Not enough balance", "error");
    } else if (value > approvalValue) {
      showSnackbarFunc("Value should not be greater than allowance", "error");
    } else {
      showSnackbarFunc(
        `Amount should be greater than or equal to ${minStakingAmount}`,
        "error"
      );
    }
  };

  const handleClickOpen = () => {
    console.log("handleClickOpen => ", {
      approvalValue,
      userInput,
      modalLoadText,
      modalText,
    });
    if (Number(userInput) <= Number(approvalValue)) {
      setModalText(
        `Are you sure that you want to stake ${userInput} ${APP_TOKEN_NAME}?`
      );
      setModalLoadText(`Staking ${userInput} ${APP_TOKEN_NAME}`);
    } else if (Number(userInput) > Number(approvalValue)) {
      setModalText(`Get approval for amount ${userInput}`);
      setModalLoadText(``);
    }

    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  let modalFunc = () => {
    if (Number(userInput) > Number(approvalValue)) {
      giveApproval(
        userInput,

        setApprovalValue,
        context.account,
        () => {},
        changeLoadingStatus,
        setOpen,
        showSnackbarFunc
      );
    } else {
      onStake(userInput, tokenBalance);
    }
  };
  const showSnackbarFunc = (data, severity) => {
    dispatch(showSnackbar({ message: data, severity }));
  };

  const onChangeInput = (input) => {
    //if check to ensure that input does not accept spacez
    if (input.includes(" ")) {
      setUserInput("");
      return;
    }

    setInputErrText("");
    setShowInputErr(false);
    if (Number.isNaN(Number(input))) {
      // setInputErrText(`Please enter only valid values`);
      // setShowInputErr(true);
      setUserInput("");
    }
    if (Number(input) <= tokenBalance) {
      setInputErrText("");
      setShowInputErr(false);
      setUserInput(input);
      if (Number(input) < minStakingAmount) {
        setInputErrText(
          `Insufficient amount. Minimum staking amount must be ${minStakingAmount}`
        );
        setShowInputErr(true);
      }
    } else {
      if (Number(input)) {
        setUserInput(input);
        setInputErrText("Your current balance is less than the amount entered");
        setShowInputErr(true);
      }
    }
  };

  const onMaxClick = () => {
    if (Number(tokenBalance) >= minStakingAmount) {
      setShowInputErr(false);
      setInputErrText("");
      setUserInput(Number(tokenBalance)?.toFixed(2));
    } else {
      setShowInputErr(true);
      setInputErrText(
        `Insufficient amount. Minimum staking amount must be ${minStakingAmount}`
      );
      setUserInput(Number(tokenBalance)?.toFixed(2));
    }
  };

  return (
    <div className={classes.container}>
      {/*  */}
      <CustomModal
        open={open}
        onClose={handleClose}
        text={modalText}
        modalFunc={modalFunc}
        loadText={modalLoadText}
        stakingPeriod={stakingPeriod}
        stakingPeriodValue={stakingPeriodValue}
        userInput={userInput}
        approvalValue={approvalValue}
      />
      <Box>
        <div className={classes.stakeContainer}>
          <Card elevation={1} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div className={classes.headerContainer}>
                <div></div>
                <Typography className={classes.title}>Super Stake</Typography>
                {currentNetwork === 3 ||
                currentNetwork === 4 ||
                currentNetwork === 2 ? (
                  <div className={classes.gasContainer}></div>
                ) : (
                  <div className={classes.gasContainer}>
                    <LocalGasStation className={classes.gasIcon} />
                    <Typography className={classes.gasTitle}>
                      {gasPrice ? gasPrice?.safeGasPrice : 0}
                    </Typography>
                  </div>
                )}
              </div>
              <div className={classes.horizontalLine}></div>
              {(currentNetwork === 3 ||
                currentNetwork === 4 ||
                currentNetwork === 2) && (
                <div className={classes.withdrawInfo}>
                  <img src={InfoPng} className={classes.infoIcon} />
                  <Typography className={classes.InfoText}>
                    {currentNetwork === 2
                      ? APP_STAKING_MESSAGE_ETH
                      : APP_STAKING_MESSAGE_POLYGON}
                  </Typography>
                </div>
              )}

              <AmountInput
                userBalance={tokenBalance}
                minStakingAmount={minStakingAmount}
                minStakingPeriod={minStakingPeriod}
                userInput={userInput}
                setUserInput={setUserInput}
                stakedAmount={userData.dafiStaked}
                inputErrText={inputErrText}
                setInputErrText={setInputErrText}
                showInputErr={showInputErr}
                setShowInputErr={setShowInputErr}
                rewards={userData.rewards}
                disabled={currentNetwork === 1 || tokenBalance <= 0}
                maxDisabled={tokenBalance <= 0}
                onChangeInput={onChangeInput}
                onMaxClick={onMaxClick}
                type="stake"
              />

              <div className={classes.marginBtm}></div>

              <BlockBtn
                label={!loading ? "Approve" : "Transaction Pending"}
                handleClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  Number(userInput) <= Number(approvalValue) ||
                  !context.active
                }
              />
              <div className={classes.marginBtm}></div>
              <BlockBtn
                label={!loading ? "Stake" : "Transaction Pending"}
                handleClick={handleClickOpen}
                disabled={
                  showInputErr ||
                  loading ||
                  !userInput ||
                  userInput > Number(approvalValue) ||
                  !context.active
                }
              />
            </CardContent>
          </Card>
        </div>
      </Box>
    </div>
  );
};

export default Stake;

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.cardBgColor,
    border: "2px solid rgba(255, 255, 255, 0.13)",
    borderRadius: 25,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    boxShadow: "none",
    width: "473px",
    position: "relative",
    "@media(max-width:550px)": {
      width: "90%",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    overflowY: "scroll",
    border: 0,
  },
  container: {
    height: "100%",
    "@media(max-width:550px)": {
      marginLeft: "-16px",
    },
    "@media(max-width:320px)": {
      marginLeft: "0px",
    },
  },
  horizontalLine: {
    borderBottom: "1px solid #ffffff1A",
    width: "120%",
    position: "absolute",
    top: "54px",
  },
  infoIcon: {
    width: "20px",
    marginRight: "10px",
    "@media(max-width:550px)": {
      marginBottom: "6px",
    },
  },

  InfoText: {
    fontSize: "14px",
    lineHeight: "19px",
    fontWeight: 400,
  },

  marginBtm: {
    marginBottom: "13px",
  },

  title: {
    fontWeight: 600,
    fontSize: "18px",
    color: "#ffffff",
    marginLeft: 40,
  },

  stakeContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  headerContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "38px",
    justifyContent: "space-between",
  },

  gasIcon: {
    color: "#23FCCF",
    marginRight: "5px",
  },

  gasTitle: {
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "21px",
    color: "#fff",
  },

  gasContainer: {
    display: "flex",
    alignItems: "center",
  },

  withdrawInfo: {
    backgroundColor: theme.palette.cardBgColor,
    border: theme.palette.border,

    color: "#FFF",
    padding: "14px",
    width: "100%",
    borderRadius: "10px",
    // textAlign: "center",
    marginBottom: "20px",
    marginTop: "-10px",
    fontWeight: 300,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    "@media(max-width:550px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
  },
}));
