import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";

import { CopyIcon } from "../assets";
import { useSelector } from "react-redux";
import { conciseAddress, pad, sixDigitsFormatter } from "../utils/formatters";
import { fromWei } from "../contract/functions/functions";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSnackbar } from "../hooks/uiHooks";
import moment from "moment";
import { APP_TOKEN_NAME } from "../utils/constants";

const BasicTable = ({ filter = "Stake", setTxLength, transactions, txNo }) => {
  const classes = useStyles();

  const { showSnackbarF } = useSnackbar();

  return (
    <TableContainer className={classes.container}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.headerRowText}>No.</TableCell>
            <TableCell className={classes.headerRowText}>Amount</TableCell>
            <TableCell className={classes.headerRowText}>Address</TableCell>
            <TableCell className={classes.headerRowText}>Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((row, index) => (
            <TableRow key={row.tx_hash} style={{ padding: "10px 0" }}>
              <TableCell className={classes.rowText}>
                {pad(txNo + index + 1)}
              </TableCell>
              <TableCell className={classes.rowText}>
                {sixDigitsFormatter(fromWei(row.amount)) + ` ${APP_TOKEN_NAME}`}
              </TableCell>
              <TableCell className={classes.addressRow}>
                <div style={{ minWidth: 100 }}>
                  {conciseAddress(row.staker)}
                </div>

                <CopyToClipboard
                  text={row.staker}
                  onCopy={() => {
                    showSnackbarF({
                      message: "Address Copied",
                      severity: "success",
                    });
                  }}
                >
                  <Button className={classes.copyBtn}>
                    <img src={CopyIcon} />
                  </Button>
                </CopyToClipboard>
              </TableCell>
              <TableCell className={classes.rowText}>
                {moment(row.time * 1000).fromNow(true)} ago
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BasicTable;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  container: {
    background: "transparent",
  },

  rowText: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",

    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "10px 0",
  },

  addressRow: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",

    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
    padding: "10px 0",
  },

  headerRowText: {
    color: "#BFB8BF",
    fontSize: "14px",
    lineHeight: "24px",
    border: "none",
    background: "transparent",
    padding: "10px 0",
  },

  copyBtn: {
    padding: 0,
    minWidth: "auto",
  },
});
