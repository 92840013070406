import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { TrendingDownSvg, TrendingUpSvg } from "../../assets/index";

const TokenStat = ({ label, value, trend }) => {
  const classes = useStyles({ trend });

  return (
    <Box className={classes.container}>
      <Typography className={classes.label}>{label}</Typography>
      <Box display="flex">
        <Typography className={classes.value}>{value}</Typography>
        {trend && (
          <Box display="flex" alignItems="center">
            <Typography className={[classes.trend]}>{`${trend}%`}</Typography>
            {trend > 0 ? (
              <img src={TrendingUpSvg} />
            ) : (
              <img src={TrendingDownSvg} />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TokenStat;

const useStyles = makeStyles((theme) => ({
  container: {
    borderBottom: "2px solid rgba(255, 255, 255, 0.02)",
    padding: "20px 0",
  },

  label: {
    fontSize: "12px",
    lineHeight: "20px",
    color: "#fff",
    marginBottom: 5,
    fontWeight: 400,
  },

  value: {
    fontSize: "20px",
    lineHeight: "20px",
    color: "#fff",
    fontWeight: 600,
    marginRight: 7,
  },

  trend: {
    fontSize: "14px",

    fontWeight: 400,
    letterSpacing: "-0.5px",

    color: (props) => (props.trend > 0 ? "#79E77D" : "#FF3636"),
    marginRight: 3,
  },
}));
