import Web3 from "web3";

import { abi } from "../abi/DafiABI.json";

import { abi as NetworkDemandABI } from "../abi/NetworkDemand.json";

import { abi as StakingABIV2 } from "../abi/StakingABIV2.json";
import { abi as DatabaseABIV2 } from "../abi/DatabaseABIV2.json";

import * as contractAddresses from "../constants";
import dafiSingle from "../../assets/d-logo.png";
import { APP_TOKEN_NAME } from "../../utils/constants";
import BigNumber from "bignumber.js";
import { AppLogoMetamask, PolygenLogo } from "../../assets";

let web3;

let networkingDemandContract;

let stakingContract;
let databaseContract;

let erc20Contract;

const EIGHT_DECIMALS = 100_000_000;
const eighteen_decimals = 1000000000000000000;

/* =============================================================================  */
// Initialization
export const initializeWeb3 = async (
  chainId,
  provider,
  currentNetwork,
  isTrustWallet,
  onUpdateContract
) => {
  console.log("initializeWeb3 start", {
    chainId,
    provider,
    currentNetwork,
    isTrustWallet,
    onUpdateContract,
  });

  web3 = new Web3(provider);

  if (chainId === 1 && currentNetwork === 2) {
    stakingContract = new web3.eth.Contract(
      StakingABIV2,

      contractAddresses.STAKING_MANAGER_ETHEREUM_MAINNET
    );
    databaseContract = new web3.eth.Contract(
      DatabaseABIV2,
      contractAddresses.STAKING_DATABASE_ETHEREUM_MAINNET
    );
    erc20Contract = new web3.eth.Contract(
      abi,
      contractAddresses.TOKEN_ADDRESS_ETHEREUM_MAINNET
    );

    networkingDemandContract = new web3.eth.Contract(
      NetworkDemandABI,
      contractAddresses.NETWORK_DEMAND_ETHEREUM_MAINNET
    );

    onUpdateContract();
  } else if (chainId === 80001 && currentNetwork === 4) {
    stakingContract = new web3.eth.Contract(
      StakingABIV2,

      contractAddresses.STAKING_MANAGER_POLYGON_MUMBAI
    );
    databaseContract = new web3.eth.Contract(
      DatabaseABIV2,
      contractAddresses.STAKING_DATABASE_POLYGON_MUMBAI
    );
    erc20Contract = new web3.eth.Contract(
      abi,
      contractAddresses.DAFI_ADDRESS_POLYGON_MUMBAI
    );

    networkingDemandContract = new web3.eth.Contract(
      NetworkDemandABI,
      contractAddresses.NETWORK_DEMAND_POLYGON_MUMBAI
    );

    onUpdateContract();
  } else if (chainId === 137 && currentNetwork === 3) {
    stakingContract = new web3.eth.Contract(
      StakingABIV2,
      contractAddresses.STAKING_MANAGER_POLYGON_MAINNET
    );
    databaseContract = new web3.eth.Contract(
      DatabaseABIV2,
      contractAddresses.STAKING_DATABASE_POLYGON_MAINNET
    );
    erc20Contract = new web3.eth.Contract(
      abi,
      contractAddresses.TOKEN_ADDRESS_POLYGON_MAINNET
    );

    networkingDemandContract = new web3.eth.Contract(
      NetworkDemandABI,
      contractAddresses.NETWORK_DEMAND_POLYGON_MAINNET
    );

    onUpdateContract();
  } else if (isTrustWallet && currentNetwork === 3) {
    stakingContract = new web3.eth.Contract(
      StakingABIV2,
      contractAddresses.STAKING_MANAGER_POLYGON_MAINNET
    );
    databaseContract = new web3.eth.Contract(
      DatabaseABIV2,
      contractAddresses.STAKING_DATABASE_POLYGON_MAINNET
    );
    erc20Contract = new web3.eth.Contract(
      abi,
      contractAddresses.TOKEN_ADDRESS_POLYGON_MAINNET
    );

    networkingDemandContract = new web3.eth.Contract(
      NetworkDemandABI,
      contractAddresses.NETWORK_DEMAND_POLYGON_MAINNET
    );
  } else if (isTrustWallet && currentNetwork === 2) {
    stakingContract = new web3.eth.Contract(
      StakingABIV2,

      contractAddresses.STAKING_MANAGER_ETHEREUM_MAINNET
    );
    databaseContract = new web3.eth.Contract(
      DatabaseABIV2,
      contractAddresses.STAKING_DATABASE_ETHEREUM_MAINNET
    );
    erc20Contract = new web3.eth.Contract(
      abi,
      contractAddresses.TOKEN_ADDRESS_ETHEREUM_MAINNET
    );

    networkingDemandContract = new web3.eth.Contract(
      NetworkDemandABI,
      contractAddresses.NETWORK_DEMAND_ETHEREUM_MAINNET
    );
  }

  console.log("addresses => ", {
    erc20Contract,
    stakingContract,
    databaseContract,
    networkingDemandContract,
  });
};

const rebaseStake = async (
  user,
  currentWeight,
  currentFeeWeight,
  time,
  fullOrPartial
) => {
  let {
    amount,
    createdOn,
    lastUpdatedOn,
    totalUnclaimed,
    feeBalance,
    lastStakingAccumulatedWeight,
    lastAccumulatedFeeWeight,
  } = await databaseContract.methods.getUserStake(user).call();

  lastUpdatedOn = BigNumber(lastUpdatedOn);
  totalUnclaimed = BigNumber(totalUnclaimed);
  feeBalance = BigNumber(feeBalance);
  lastStakingAccumulatedWeight = BigNumber(lastStakingAccumulatedWeight);
  console.log("Total Unclaimed", totalUnclaimed.toString() / eighteen_decimals);

  let currentAccumulatedWeight = BigNumber(currentWeight);
  let lastAccumulatedWeight = lastStakingAccumulatedWeight;
  let newReward = currentAccumulatedWeight
    .minus(lastAccumulatedWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);
  let currentAccumulatedFeeWeight = BigNumber(currentFeeWeight);
  let newFee = currentAccumulatedFeeWeight
    .minus(lastAccumulatedFeeWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);
  lastStakingAccumulatedWeight = currentAccumulatedWeight;
  console.log("new reward", newReward.toString());
  console.log("new fee", newFee.toString());

  totalUnclaimed = totalUnclaimed.plus(newReward);
  lastUpdatedOn = time;
  feeBalance = newFee.plus(feeBalance);
  lastAccumulatedFeeWeight = currentAccumulatedFeeWeight;
  console.log("LastStakingAcc", lastStakingAccumulatedWeight.toString());
  console.log(
    "totalUnclaimed",
    totalUnclaimed.div(eighteen_decimals).toString()
  );
  console.log("lastUpdatedOn", lastUpdatedOn.toString());
  console.log("feeBalance", feeBalance.toString());
  console.log("lastAccumulatedFeeWeight", lastAccumulatedFeeWeight.toString());

  let d = await computer(totalUnclaimed, feeBalance, fullOrPartial);

  return d;
};

/* =============================================================================  */
// Utils functions
export const fromWei = (value) => {
  return Web3.utils.fromWei(value, "ether");
};

/* =============================================================================  */
// approval functions
export const giveApproval = async (
  userInput,
  setApprovalValue,
  sourceAddress,
  setMessage,
  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {}
) => {
  console.log("sourceAddress => ", sourceAddress);

  setLoading(true);
  try {
    await erc20Contract.methods
      .approve(stakingContract._address, web3.utils.toWei(userInput))
      .send({ from: sourceAddress /*** selected account from metamask ***/ }) //
      .on("transactionHash", (hash) => {
        // hash of tx
      })
      .on("confirmation", function (confirmationNumber, receipt) {
        if (confirmationNumber === 1) {
          showSnackbarFunc(`Approval given for amount ${userInput}`, "success");
          setModalOpen(false);
          setLoading(false);
          setApprovalValue(userInput);
          // setUserInput("");
          setMessage(`Approval Given to ${sourceAddress}`);
        }
      });
  } catch (error) {
    setLoading(false);
    setModalOpen(false);
    showSnackbarFunc("Approval failed", "error");
    setApprovalValue("");
    console.log(error);
  }
};

export const checkApproval = async (sourceAddress) => {
  console.log("account 0", stakingContract);
  return await erc20Contract.methods
    .allowance(sourceAddress, stakingContract._address)
    .call(); // contract.methods.methodName(parameters).send({from:selected account})
};

/* =============================================================================  */
// staking functions
export const stakeAmount = async (
  amount,
  sourceAddress,
  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {},
  setUserInput,
  onSuccess
) => {
  console.log(web3.utils.toWei(amount.toString()));

  console.log(stakingContract.methods);
  setLoading(true);
  try {
    await stakingContract.methods
      .stake(web3.utils.toWei(amount.toString()))
      .send({ from: sourceAddress })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);
          // console.log("Receipt ==>>", receipt);
          if (receipt) {
            showSnackbarFunc(
              `You have staked ${amount} ${APP_TOKEN_NAME}`,
              "success"
            );
            setModalOpen(false);
            setLoading(false);

            setUserInput("");
            onSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", async function (confirmationNumber, receipt) {});
  } catch (err) {
    showSnackbarFunc("Staking failed or rejected", "error");
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};

export const unStakeAmount = async (
  amount,
  sourceAddress,

  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {},
  setUserInput,
  onSuccess
) => {
  console.log(stakingContract.methods);
  setLoading(true);
  try {
    await stakingContract.methods
      .unstake(web3.utils.toWei(amount.toString()))
      .send({ from: sourceAddress })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);
          // console.log("Receipt ==>>", receipt);
          if (receipt) {
            showSnackbarFunc(
              `You've unstaked ${amount} ${APP_TOKEN_NAME}`,
              "success"
            );
            setModalOpen(false);
            setLoading(false);

            setUserInput("");
            onSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", async function (confirmationNumber, receipt) {
        if (confirmationNumber == 1) {
        }
      });
  } catch (err) {
    showSnackbarFunc("Unstaking failed or rejected", "error");
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};

export const withdrawAmount = async (
  sourceAddress,

  setLoading = () => {},
  setModalOpen = () => {},
  showSnackbarFunc = () => {},
  partialClaim,
  amount,
  setUserInput,
  onSuccess
) => {
  console.log(stakingContract.methods);
  console.log(partialClaim, amount);
  setLoading(true);
  try {
    await stakingContract.methods
      .claimRewards(partialClaim, web3.utils.toWei(amount.toString()))
      .send({ from: sourceAddress })
      .on("transactionHash", (hash) => {
        var intervalVar = setInterval(async () => {
          let receipt = await web3.eth.getTransactionReceipt(hash);
          // console.log("Receipt ==>>", receipt);
          if (receipt) {
            showSnackbarFunc(
              "You've successfully withdrawn your rewards",
              "success"
            );
            setModalOpen(false);
            setLoading(false);
            setUserInput("");
            onSuccess();
            clearInterval(intervalVar);
          }
        }, 5000);
      })
      .on("confirmation", function (confirmationNumber, receipt) {});
  } catch (err) {
    showSnackbarFunc("Withdrawal failed or rejected", "error");
    setModalOpen(false);
    setLoading(false);
    console.log(err);
  }
};

/* =============================================================================  */
// stats functions
export const totalAmountStaked = async (address) => {
  console.log("address 0", address, databaseContract);

  let value = await databaseContract.methods.totalStakedFor(address).call();

  return web3.utils.fromWei(value, "ether");
};
export const totalOverallAmountStaked = async () => {
  let value = await databaseContract.methods.getTotalStaked().call();

  console.log("totalAmountStaked => ", value);
  return web3.utils.fromWei(value, "ether");
};
export const getDemandFactorV2 = async (currentNetwork) => {
  console.log("getDemandFactorPolygonV2 functions");

  let contractAddress =
    currentNetwork === 3
      ? contractAddresses.NETWORK_DEMAND_POLYGON_MAINNET
      : currentNetwork === 2
      ? contractAddresses.NETWORK_DEMAND_ETHEREUM_MAINNET
      : contractAddresses.NETWORK_DEMAND_POLYGON_MUMBAI;

  console.log("getDemandFactorPolygonV2 functions var => ", {
    contractAddress,
    currentNetwork,
  });

  let networkingDemandContractV2 = new web3.eth.Contract(
    NetworkDemandABI,
    contractAddress
  );

  let newDemandFactor = await networkingDemandContractV2.methods
    .calculateNetworkDemand()
    .call();

  console.log("getDemandFactorPolygonV2 df ", newDemandFactor);

  return newDemandFactor;
};

const formatEther = (number) => {
  return number ? number / 1000000000000000000 : 0;
};

const getTotalStaked = async () => {
  let value = await databaseContract.methods.getTotalStaked().call();

  console.log("getTotalStaked => ", value);
  return value;
};

const getMaxDAFI = async () => {
  let value = await databaseContract.methods.getMaxDAFI().call();

  console.log("getMaxDAFI => ", value);
  return value;
};

export const getProgramDuration = async () => {
  let value = await databaseContract.methods.getProgramDuration().call();

  console.log("getProgramDuration => ", value);
  return value;
};

export const getRewardBalanceV2 = async (account, currentNetwork) => {
  console.log("inside contract getRewardBalanceV2");

  let {
    totalUnclaimed,
    lastStakingAccumulatedWeight,
    amount,
    lastAccumulatedFeeWeight,
  } = await getStakeDetailsV2(account);
  let { accumulatedPoolWeight, accumulatedFeeWeight, pool } =
    await getPoolDetailsV2();

  let newDemandFactor = await getDemandFactorV2(currentNetwork);

  let totalStaked = await getTotalStaked();

  let maxDAFI = await getMaxDAFI();

  let programDuration = await getProgramDuration();

  console.log("getRewardBalanceV2 beforeBGN => ", {
    totalUnclaimed,
    lastStakingAccumulatedWeight,
    amount,
    lastAccumulatedFeeWeight,
    accumulatedPoolWeight,
    accumulatedFeeWeight,
  });

  totalUnclaimed = new BigNumber(totalUnclaimed);
  const EIGHT_DECIMALS = "100000000";

  accumulatedPoolWeight = new BigNumber(accumulatedPoolWeight);

  lastStakingAccumulatedWeight = new BigNumber(lastStakingAccumulatedWeight);

  lastAccumulatedFeeWeight = new BigNumber(lastAccumulatedFeeWeight);

  accumulatedFeeWeight = new BigNumber(accumulatedFeeWeight);

  amount = new BigNumber(amount);

  maxDAFI = new BigNumber(maxDAFI);

  programDuration = new BigNumber(programDuration);

  newDemandFactor = new BigNumber(newDemandFactor);

  let mdi = new BigNumber(maxDAFI.div(programDuration));

  let dps = mdi
    .multipliedBy(newDemandFactor.div(EIGHT_DECIMALS))
    .multipliedBy(amount.div(totalStaked));

  let now = new BigNumber(Math.floor(Date.now() / 1000));

  const lastPoolUpdated = new BigNumber(pool.lastUpdatedOn);

  const unrecordedReward = now.minus(lastPoolUpdated).multipliedBy(dps);

  console.log("bigNumber start => ", {
    totalUnclaimed,
    EIGHT_DECIMALS,

    accumulatedPoolWeight,
    lastStakingAccumulatedWeight,
    amount,
    lastAccumulatedFeeWeight,
    accumulatedFeeWeight,
  });

  const baseReward = totalUnclaimed
    .plus(
      accumulatedPoolWeight
        .minus(lastStakingAccumulatedWeight)
        .multipliedBy(amount)
        .div(EIGHT_DECIMALS)
    )
    .multipliedBy(newDemandFactor)
    .div(EIGHT_DECIMALS);

  const feeReward = accumulatedFeeWeight
    .minus(lastAccumulatedFeeWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);

  const rewardBN = baseReward.plus(feeReward);

  const finalReward = unrecordedReward.plus(rewardBN);

  console.log("bigNumber => ", {
    newDemandFactor,
    totalUnclaimed,
    accumulatedPoolWeight,
    baseReward: baseReward.toFixed(10),
    feeReward: feeReward.toFixed(10),
    rewardBNW: rewardBN.toString(),
    rewardBN: formatEther(rewardBN.toString()),
    unrecordedReward,
    finalReward: formatEther(finalReward.toString()),
  });

  let reward = formatEther(finalReward.toString());

  return reward ? reward : 0;
};

export const getPotentialBalanceV2 = async (account, currentNetwork) => {
  console.log("inside contract getPotentialBalanceV2");

  let {
    totalUnclaimed,
    lastStakingAccumulatedWeight,
    amount,
    lastAccumulatedFeeWeight,
  } = await getStakeDetailsV2(account);
  let { accumulatedPoolWeight, accumulatedFeeWeight, pool } =
    await getPoolDetailsV2();

  let newDemandFactor;

  newDemandFactor = await getDemandFactorV2(currentNetwork);

  let totalStaked = await getTotalStaked();

  let maxDAFI = await getMaxDAFI();

  let programDuration = await getProgramDuration();

  totalUnclaimed = new BigNumber(totalUnclaimed);
  const EIGHT_DECIMALS = "100000000";

  accumulatedPoolWeight = new BigNumber(accumulatedPoolWeight);

  lastStakingAccumulatedWeight = new BigNumber(lastStakingAccumulatedWeight);

  lastAccumulatedFeeWeight = new BigNumber(lastAccumulatedFeeWeight);

  accumulatedFeeWeight = new BigNumber(accumulatedFeeWeight);

  amount = new BigNumber(amount);

  maxDAFI = new BigNumber(maxDAFI);

  programDuration = new BigNumber(programDuration);

  newDemandFactor = new BigNumber(newDemandFactor);

  let mdi = new BigNumber(maxDAFI.div(programDuration));

  let dps = mdi
    .multipliedBy(newDemandFactor.div(EIGHT_DECIMALS))
    .multipliedBy(amount.div(totalStaked));

  let now = new BigNumber(Math.floor(Date.now() / 1000));

  const lastPoolUpdated = new BigNumber(pool.lastUpdatedOn);

  const unrecordedReward = now.minus(lastPoolUpdated).multipliedBy(dps);

  console.log("bigNumber start => ", {
    totalUnclaimed,
    EIGHT_DECIMALS,

    accumulatedPoolWeight,
    lastStakingAccumulatedWeight,
    amount,
    lastAccumulatedFeeWeight,
    accumulatedFeeWeight,
  });

  const baseReward = totalUnclaimed.plus(
    accumulatedPoolWeight
      .minus(lastStakingAccumulatedWeight)
      .multipliedBy(amount)
      .div(EIGHT_DECIMALS)
  );

  const feeReward = accumulatedFeeWeight
    .minus(lastAccumulatedFeeWeight)
    .multipliedBy(amount)
    .div(EIGHT_DECIMALS);

  const rewardBN = baseReward.plus(feeReward);

  const finalReward = unrecordedReward.plus(rewardBN);

  console.log("bigNumber => ", {
    newDemandFactor,
    totalUnclaimed,
    accumulatedPoolWeight,
    baseReward: baseReward.toFixed(10),
    feeReward: feeReward.toFixed(10),
    rewardBNW: rewardBN.toString(),
    rewardBN: formatEther(rewardBN.toString()),
    unrecordedReward,
    finalReward: formatEther(finalReward.toString()),
  });

  let reward = formatEther(finalReward.toString());

  return reward ? reward : 0;
};

export const getTotalFeesCollectedV2 = async () => {
  let value = await databaseContract.methods.getTotalFeesCollected().call();

  console.log("getTotalFeesCollectedV2 > ", value);

  return value ? fromWei(value) : 0;
};

export const getStakeDetailsV2 = async (address) => {
  let value = await databaseContract.methods.getUserStake(address).call();

  console.log("getStakeDetailsV2 > ", value);

  return value;
};

export const getPoolDetailsV2 = async () => {
  let accumulatedPoolWeight = await databaseContract.methods
    .getAccumulatedPoolWeight()
    .call();

  let accumulatedFeeWeight = await databaseContract.methods
    .getAccumulatedFeeWeight()
    .call();

  let pool = await databaseContract.methods.getPool().call();
  console.log("getPoolDetailsV2 > ", {
    accumulatedPoolWeight,
    accumulatedFeeWeight,
    pool,
  });

  return {
    accumulatedPoolWeight,
    accumulatedFeeWeight,
    pool,
  };
};

export const getMinimumStakeAmount = async () => {
  let minStakingAmount = await databaseContract.methods
    .getMinimumStakeAmount()
    .call();

  console.log("minStakingAmount => ", minStakingAmount);

  return fromWei(minStakingAmount);
};

export const getMinimumStakePeriod = async () => {
  let minStakingPeriod = await databaseContract.methods
    .getMinimumStakePeriod()
    .call();

  console.log("getMinimumStakePeriod => ", minStakingPeriod);

  return minStakingPeriod;
};

/* =============================================================================  */
// utils functions

export const getBalance = async (account) => {
  let balance = await erc20Contract.methods.balanceOf(account).call();
  return web3.utils.fromWei(balance, "ether");
};

const claimReward = async (time, fullOrPartial, account) => {
  let {
    lastUpdatedOn,
    currentAccumulatedWeight,
    currentPoolWeight,
    accumulatedFeeWeight,
    currentFeeWeight,
  } = await databaseContract.methods.getPool().call();

  let firstRebaseTime = new BigNumber(
    await databaseContract.methods.getFirstRebaseTime().call()
  );
  let programDuration = new BigNumber(
    await databaseContract.methods.getProgramDuration().call()
  );

  let feeDeposited = new BigNumber(
    await databaseContract.methods.getFeesDeposited().call()
  );

  let blockTimeStamp = new BigNumber(time);
  let elapsedTime = blockTimeStamp.minus(lastUpdatedOn);

  let maxDafi = new BigNumber(
    await databaseContract.methods.getMaxDAFI().call()
  );

  let dDAFIDistributedCurrent = BigNumber(
    await databaseContract.methods.getDistributePerSecond().call()
  ).multipliedBy(elapsedTime);

  let totaldDAFIDistributed = new BigNumber(
    await databaseContract.methods.getdDAFIDistributed().call()
  ).plus(dDAFIDistributedCurrent);

  let MDICurrent = maxDafi
    .minus(totaldDAFIDistributed)
    .div(programDuration.minus(blockTimeStamp.minus(firstRebaseTime)));

  let totalStaked = new BigNumber(
    await databaseContract.methods.getTotalStaked().call()
  );
  let tempCurrentPoolWeight = MDICurrent.multipliedBy(
    elapsedTime.multipliedBy(EIGHT_DECIMALS)
  ).div(totalStaked);
  let tempCurrentFeeWeight = feeDeposited
    .multipliedBy(EIGHT_DECIMALS)
    .div(totalStaked);

  lastUpdatedOn = blockTimeStamp;
  currentAccumulatedWeight = tempCurrentPoolWeight.plus(
    currentAccumulatedWeight
  );
  currentPoolWeight = tempCurrentPoolWeight;
  accumulatedFeeWeight = tempCurrentFeeWeight.plus(accumulatedFeeWeight);
  currentFeeWeight = tempCurrentFeeWeight;

  console.log(
    lastUpdatedOn.toString(),
    currentAccumulatedWeight.toString(),
    currentPoolWeight.toString(),
    accumulatedFeeWeight.toString(),
    currentFeeWeight.toString()
  );
  console.log("Ddafi distributed", totaldDAFIDistributed.toString());
  console.log("Ddafi dps", MDICurrent.toString());
  console.log("temp fee weight ", currentFeeWeight.toString());
  console.log("temp pool weight ", currentPoolWeight.toString());

  let e = await rebaseStake(
    account,
    currentAccumulatedWeight,
    accumulatedFeeWeight,
    time,
    fullOrPartial
  );

  return e;
};

export const registerToken = async () => {
  const tokenAddress = erc20Contract._address;
  const tokenSymbol = APP_TOKEN_NAME;
  const tokenDecimals = 18;
  const tokenImage = AppLogoMetamask;

  console.log("Web3 => ", { web3, Web3, ethereum: window.ethereum });

  try {
    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: "wallet_watchAsset",
      params: {
        type: "ERC20", // Initially only supports ERC20, but eventually more!
        options: {
          address: tokenAddress, // The address that the token is at.
          symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
          decimals: tokenDecimals, // The number of decimals in the token
          image: tokenImage, // A string url of the token logo
        },
      },
    });

    if (wasAdded) {
      console.log("Thanks for your interest!");
    } else {
      console.log("Your loss!");
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendTransaction = async (chainId, updateNetwork) => {
  let chainData = {};
  if (chainId === "0x89") {
    chainData = {
      chainId: "0x89",
      chainName: "Polygon",
      rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
      nativeCurrency: {
        name: "MATIC",
        symbol: "MATIC",
        decimals: 18,
      },
      blockExplorerUrls: ["https://polygonscan.com/"],
    };
  } else if (chainId === "0x38") {
    chainData = {
      chainId: "0x38",
      chainName: "Binance",
      rpcUrls: ["https://bsc-dataseed.binance.org/"],
      nativeCurrency: {
        name: "BNB",
        symbol: "BNB",
        decimals: 18,
      },
      blockExplorerUrls: ["https://bscscan.com"],
    };
  }
  try {
    console.log("Web3 => ", { web3, ethereum: window.ethereum });
    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(function () {
        window.ethereum
          .request({
            method: "wallet_switchEthereumChain",
            params: [
              {
                chainId,
              },
            ],
          })
          .then(() => {
            updateNetwork();
          })
          .catch((error) => {
            console.log(error.code === 4902);
            if (error.code === 4902) {
              addChainToMetamask(chainData, updateNetwork);
            }
          });
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (error) {
    console.log("Caught => ", error);
  }
};
const dafiAccurateRewardSimulation = async (account, input = 0) => {
  let fullOrPartial = web3.utils.toWei(`${input}`);
  //prod
  let time = BigNumber(Math.floor(Date.now() / 1000));

  console.log("dafiAccurateRewardSimulation => ", { time });

  let { reward, potentialReward } = await claimReward(
    time,
    fullOrPartial,
    account
  );
  console.log("hello");
  console.log(
    "reward disbursed is ",
    reward,
    "potential reward disbursed is",
    potentialReward
  );

  return {
    reward,
    potentialReward,
  };
};

export const getRewardBalanceV2Binance = async (
  account,
  currentNetwork,
  chainId,
  input = 0
) => {
  console.log("inside contract getRewardBalanceV2Binance =>", {
    account,
    currentNetwork,
    chainId,
  });

  let { reward, potentialReward } = await dafiAccurateRewardSimulation(
    account,
    input
  );

  console.log("Reward  dafiAccurateRewardSimulation=> ", reward);

  return reward ? Number(reward) : 0;

  // let {
  //   totalUnclaimed,
  //   lastStakingAccumulatedWeight,
  //   amount,
  //   lastAccumulatedFeeWeight,
  // } = await getStakeDetailsV2(account);
  // let { accumulatedPoolWeight, accumulatedFeeWeight, pool } =
  //   await getPoolDetailsV2();

  // let newDemandFactor;

  // if (currentNetwork === 2 || currentNetwork === 5) {
  //   newDemandFactor = await getDemandFactorV2(chainId);
  // } else if (currentNetwork === 3) {
  //   newDemandFactor = await getDemandFactorPolygonV2(chainId);
  // }

  // let totalStaked = await getTotalStaked();

  // let maxDAFI = await getMaxDAFI();

  // let programDuration = await getProgramDuration();

  // totalUnclaimed = new BigNumber(totalUnclaimed);
  // const EIGHT_DECIMALS = "100000000";

  // accumulatedPoolWeight = new BigNumber(accumulatedPoolWeight);

  // lastStakingAccumulatedWeight = new BigNumber(lastStakingAccumulatedWeight);

  // lastAccumulatedFeeWeight = new BigNumber(lastAccumulatedFeeWeight);

  // accumulatedFeeWeight = new BigNumber(accumulatedFeeWeight);

  // amount = new BigNumber(amount);

  // maxDAFI = new BigNumber(maxDAFI);

  // programDuration = new BigNumber(programDuration);

  // newDemandFactor = new BigNumber(newDemandFactor);

  // let mdi = new BigNumber(maxDAFI.div(programDuration));

  // let dps = mdi
  //   .multipliedBy(newDemandFactor.div(EIGHT_DECIMALS))
  //   .multipliedBy(amount.div(totalStaked));

  // let now = new BigNumber(Math.floor(Date.now() / 1000));

  // const lastPoolUpdated = new BigNumber(pool.lastUpdatedOn);

  // const unrecordedReward = now.minus(lastPoolUpdated).multipliedBy(dps);

  // console.log("bigNumber start => ", {
  //   totalUnclaimed,
  //   EIGHT_DECIMALS,

  //   accumulatedPoolWeight,
  //   lastStakingAccumulatedWeight,
  //   amount,
  //   lastAccumulatedFeeWeight,
  //   accumulatedFeeWeight,
  // });

  // const baseReward = totalUnclaimed
  //   .plus(
  //     accumulatedPoolWeight
  //       .minus(lastStakingAccumulatedWeight)
  //       .multipliedBy(amount)
  //       .div(EIGHT_DECIMALS)
  //   )
  //   .multipliedBy(newDemandFactor)
  //   .div(EIGHT_DECIMALS);

  // const feeReward = accumulatedFeeWeight
  //   .minus(lastAccumulatedFeeWeight)
  //   .multipliedBy(amount)
  //   .div(EIGHT_DECIMALS);

  // const rewardBN = baseReward.plus(feeReward);

  // const finalReward = unrecordedReward.plus(rewardBN);

  // console.log("bigNumber => ", {
  //   newDemandFactor,
  //   totalUnclaimed,
  //   accumulatedPoolWeight,
  //   baseReward: baseReward.toFixed(10),
  //   feeReward: feeReward.toFixed(10),
  //   rewardBNW: rewardBN.toString(),
  //   rewardBN: formatEther(rewardBN.toString()),
  //   unrecordedReward,
  //   finalReward: formatEther(finalReward.toString()),
  // });

  // let reward = formatEther(finalReward.toString());

  // return reward ? reward : 0;
};

export const addChainToMetamask = async (chainData, updateNetwork) => {
  try {
    console.log("Web3 => ", { web3, ethereum: window.ethereum });
    window.ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(function () {
        window.ethereum
          .request({
            method: "wallet_addEthereumChain",
            params: [chainData],
          })
          .then(() => {
            updateNetwork();
          });
      });
  } catch (error) {
    console.log(error);
  }
};

const computer = async (unclaimed, feeBalance, fullOrPartial) => {
  let demandFactor =
    (await networkingDemandContract.methods.calculateNetworkDemand().call()) /
    EIGHT_DECIMALS;
  let slashFee = (await databaseContract.methods.getRewardFee().call()) / 100;
  console.log(demandFactor, slashFee);
  //((W-Wi/1e8 * A) + U)
  let rewards = BigNumber(fullOrPartial == 0 ? unclaimed : fullOrPartial);
  console.log(rewards.div(eighteen_decimals).toString());
  //((W-Wi/1e8 * A) + U) * D/Dmax
  let rewardDF = rewards.multipliedBy(demandFactor);
  //((W-Wi/1e8 * A) + U) * D/Dmax + (F-Fi/1e8 * A)
  let rewardPlusFeeBal = rewardDF.plus(feeBalance);
  let potentialReward = rewards.plus(feeBalance);
  let fee = rewardPlusFeeBal.multipliedBy(slashFee);
  let rewardToDisburse = rewardPlusFeeBal.minus(fee);
  let potentialRewardToDisburse = potentialReward.minus(
    potentialReward.multipliedBy(slashFee)
  );
  console.log("feeBalance", feeBalance.div(eighteen_decimals).toString());
  console.log("unclaimed passed", rewards.div(eighteen_decimals).toString());
  console.log("rewardDF passed", rewardDF.div(eighteen_decimals).toString());
  console.log(
    "rewardPlusFeeBal passed",
    rewardPlusFeeBal.div(eighteen_decimals).toString()
  );
  console.log(
    "rewardToDisburse passed",
    rewardToDisburse.div(eighteen_decimals).toString()
  );
  console.log(
    "potential reward",
    potentialReward.div(eighteen_decimals).toString()
  );
  console.log(
    "potentialRewardToDisburse",
    potentialRewardToDisburse.div(eighteen_decimals).toString()
  );

  return {
    reward: rewardPlusFeeBal.div(eighteen_decimals).toString(),
    potentialReward: potentialReward.div(eighteen_decimals).toString(),
  };
};
