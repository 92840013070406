export const APP_NAME = "Polinate";
export const APP_DASHBOARD_TITLE = `${APP_NAME} Dashboard`;
export const APP_STAKE_PAGE_TITLE = `${APP_NAME} Super Staking`;

export const APP_SUPERPOOL_PAGE_TITLE = `${APP_NAME} Super Pool`;

export const APP_FONT_NAME = "Poppins";

export const APP_TOTAL_STAKED_TEXT = "Total Staked";

export const APP_YOUR_STAKED_TEXT = "Your Staked";
export const APP_FEES_COLLECTED_TEXT = "Fees Collected";
export const APP_REWARDS_TEXT = "Rewards";
export const APP_POTENTIAL_REWARDS_TEXT = "Potential";

export const APP_CARD_BG_COLOR =
  "linear-gradient(142.4deg, #380490 1.19%, #343434 114.83%), linear-gradient(142.4deg, #302B63 -8.44%, #302B63 57.82%, #24243E 114.83%);";

export const APP_CARD_BORDER = "2px solid rgba(255, 255, 255, 0.1)";

export const APP_BTN_BACKGROUND =
  "linear-gradient(142.4deg, #380490 1.19%, #343434 114.83%), linear-gradient(107.63deg, #302B63 -2.79%, #302B63 103.77%)";

export const APP_BTN_BORDER = "2px solid rgba(255, 255, 255, 0.13)";

export const APP_BTN_BOX_SHADOW =
  "inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12)";

export const APP_BTN_BORDER_RADIUS = "10px";

export const APP_STAKING_MESSAGE_POLYGON =
  "You will be staking in the Polygon Super Pool.";

export const APP_STAKING_MESSAGE_ETH =
  "You will be staking in the Ethereum Super Pool.";

export const APP_UNSTAKING_MESSAGE =
  "A 10% toll is deducted from your Poli rewards when exiting a Pool. These tokens are then distributed among Super Stakers, boosting APY.";

export const APP_TOKEN_NAME = "POLI";

export const TOP_FIVE_USERS_QUERY = `
{
  stakes(first:5,orderBy:amount, orderDirection:desc){
    id
    amount
  }
}
`;

export const TX_HISTORY_QUERY = `{
  stakehistories(first:1000,orderBy:time, orderDirection:desc){
    id
    staker
    amount
    tx_hash
    type
    time
  }
}`;

export const COIN_GECKO_BASE_URL = "https://api.coingecko.com/api/v3";

export const COIN_GECKO_COIN_ID = "polinate";

export const ETHERSCAN_API_KEY = "CH4P1UU8CNBDZD25WX9EXQY9XDGBZMG2VD";

export const ETHERSCAN_BASE_URL = "https://api.etherscan.io";

export const COIN_CIRC_SUPPLY = 28501000;

export const TWITTER_HANDLE = "@polinate_io";

export const AMOUNT_FEE_RATIO = 0.9;

export const APP_TWITTER_URL = "https://twitter.com/polinate_io";

export const APP_MEDIUM_URL = "https://polinate.medium.com/";

export const APP_DISCORD_URL = "https://discord.com/invite/r6vdcGeTZK";

export const APP_TELEGRAM_URL = "https://t.me/polinate";

export const APP_LINKEDIN_URL = " https://www.linkedin.com/company/polinate";
export const COUNT_PER_PAGE = 10;
