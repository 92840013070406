import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import {
  FeesCollectedGeneric,
  PotentialGeneric,
  RewardGeneric,
  TotalStaked,
  YourStaked,
} from "../assets";
import InfoCard from "../components/Cards/InfoCard";

import {
  APP_FEES_COLLECTED_TEXT,
  APP_POTENTIAL_REWARDS_TEXT,
  APP_REWARDS_TEXT,
  APP_TOTAL_STAKED_TEXT,
  APP_YOUR_STAKED_TEXT,
} from "../utils/constants";

const Cards = () => {
  const { totalDafiStaked, feesCollected, dafiStaked, rewards, potential } =
    useSelector((state) => state.web3.data);
  const classes = useStyles();

  const OverallTotalStakes = useSelector(
    (state) => state.web3.data.OverallTotalStakes
  );

  useEffect(() => {
    let sum = 0;

    if (OverallTotalStakes?.maticV2) {
      sum = sum + Number(OverallTotalStakes?.maticV2);
    }

    if (OverallTotalStakes?.eth) {
      sum = sum + Number(OverallTotalStakes?.eth);
    }

    if (OverallTotalStakes?.ethereumV2) {
      sum = sum + Number(OverallTotalStakes?.ethereumV2);
    }

    setCards([
      {
        text: APP_TOTAL_STAKED_TEXT,
        value: sum,

        icon: TotalStaked,
        public: true,
      },
      {
        text: APP_FEES_COLLECTED_TEXT,
        value: feesCollected,
        icon: FeesCollectedGeneric,
        public: true,
        infoText:
          "The total network fees collected, which is redistributed between all active users gradually",
      },

      {
        text: APP_YOUR_STAKED_TEXT,
        value: dafiStaked,
        icon: YourStaked,
      },

      {
        text: APP_REWARDS_TEXT,
        value: rewards,
        icon: RewardGeneric,
      },
      {
        text: APP_POTENTIAL_REWARDS_TEXT,
        value: potential,
        icon: PotentialGeneric,
        infoText:
          "This is the quantity your dToken rewards would multiply to at maximum network demand, ie. Potential APY",
      },
    ]);
  }, [
    totalDafiStaked,
    feesCollected,
    dafiStaked,
    rewards,
    potential,
    OverallTotalStakes,
  ]);

  const [cards, setCards] = useState([]);

  return (
    <>
      {cards.map((card, index) => (
        <Grid item className={classes.card}>
          <InfoCard card={card} index={index} />
        </Grid>
      ))}
    </>
  );
};

export default Cards;
const useStyles = makeStyles((theme) => ({
  card: {
    [theme.breakpoints.between("sm", "lg")]: {
      display: "flex",
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
}));
