import React from "react";
import {
  makeStyles,
  ListItem,
  List,
  Box,
  ListItemText,
  ListItemSecondaryAction,
  Typography,
} from "@material-ui/core";
import { TopUser } from "../../assets";
import { conciseAddress } from "../../utils/formatters";
import { fromWei } from "../../contract/functions/functions";

const useStyles = makeStyles({
  rowText: {
    color: "#fff",
    fontSize: "14px",
    lineHeight: "20px",
    border: "none",
  },

  rowCircle: {
    height: 40,
    width: 40,
    // background: (gradient) =>
    //   "linear-gradient(180deg, #F66511 0%, #F3B22C 100%)",
    // borderRadius: "100%",
    marginRight: 16,
  },

  listItem: {
    padding: "12px 0px",
    borderBottom: (props) =>
      props.showBorder ? "1px solid rgba(255, 255, 255, 0.06)" : "none",
  },
});

const TopUserRow = ({ address, gradient, showBorder, amount, icon }) => {
  const classes = useStyles({ gradient, showBorder });
  return (
    <ListItem className={classes.listItem}>
      {/* <Box className={classes.rowCircle} style={{ background: gradient }} /> */}

      <img src={icon} className={classes.rowCircle} />

      <ListItemText
        primary={
          <Typography className={classes.rowText}>
            {conciseAddress(address)}
          </Typography>
        }
      />

      <ListItemSecondaryAction>
        <img src={TopUser} />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default TopUserRow;
