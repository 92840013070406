export const DAFI_ADDRESS_POLYGON_MUMBAI =
  "0xe61B223100676D6Ffa588C7c6720348DCAD3131F";

export const NETWORK_DEMAND_POLYGON_MUMBAI =
  "0xF487571686c6Dc5c905409572D28f58DEC8aB4DB";

export const STAKING_DATABASE_POLYGON_MUMBAI =
  "0xd2A871713509843c0dcbA26eF7885986EDD767df";

export const STAKING_MANAGER_POLYGON_MUMBAI =
  "0x49122e3E5C30577799aaA480D097Cb2940a8faF3";

// End

// App Mainnet
export const TOKEN_ADDRESS_POLYGON_MAINNET =
  "0x6fb54ffe60386ac33b722be13d2549dd87bf63af";

export const NETWORK_DEMAND_POLYGON_MAINNET =
  "0xDaf9ABF0CB94e587921321f5c8eDE00385578F14";

export const STAKING_DATABASE_POLYGON_MAINNET =
  "0xc68350d6bC933A5aa50eb50e95611B551B1783F6";

export const STAKING_MANAGER_POLYGON_MAINNET =
  "0x993a6193d89fAF3a354D11802576CACcAFb61185";
//

// App Mainnet 2
export const TOKEN_ADDRESS_ETHEREUM_MAINNET =
  "0xa1a36d3537bbe375cc9694795f663ddc8d516db9";

export const NETWORK_DEMAND_ETHEREUM_MAINNET =
  "0xd6bd7690929610e63e1f53f89a6dbddab79ae9fe";

export const STAKING_DATABASE_ETHEREUM_MAINNET =
  "0x54CB8e9d82E59c505E0e001Bc599388a0019FC3C";

export const STAKING_MANAGER_ETHEREUM_MAINNET =
  "0x48A20c67b376B2de84F26901793Af1C6Cc9E2bEF";
//

// export const API_BASE_URL = "https://poli-server.superstaking.io";
export const API_BASE_URL = "https://poli-server.superstaking.io";
// export const API_BASE_URL = "http://172.18.0.143:5000";

export const API_DEV_BASE_URL = "34.201.210.52:3000";
export const GRAPH_BASE_URL =
  "https://api.thegraph.com/subgraphs/name/pro123908";
